import LocalIdbBase from './localIdbBase';
import {useApiComponent} from '@bit/hlouzek.rhplus.api';
import {apis} from 'api/apiService';

export default function LocalIdb(props) {
  const {
    documentStates,
    documentAllowedCombinations,
    clients
  } = apis;
  const {callApi} = useApiComponent(null);

  const {
    openDb,
    createTable, 
    getAll, 
    getById,
    addItem,
    addItems
  } = LocalIdbBase(window.indexedDB, 1);

  const open = () => {
    openDb('purchaseOrderDb', e => {
      createTable("documentStates", "id", true)
      .then(({fetchneeded}) => fetchneeded && callApi({...documentStates}).then(e => addItems("documentStates", e.data)))

      createTable("documentAllowedCombination", "id", true)
      .then(({fetchneeded}) => fetchneeded && callApi({...documentAllowedCombinations}).then(e => addItems("documentAllowedCombination", e.data)))

    })
    .then(e => console.log("success"));
  }

  return {
    open,
    getAll,
    getById,
    addItem,
    addItems
  }
}