import * as React from 'react';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import Enumerable from 'linq';

export default function ActionRenderer(props) {

  const handleClick = React.useCallback((id, action) => {
    const { data } = props;
    const { documentNumber, key } = data;
    props.context.componentParent.handleActionClick({ documentNumber, key, id, action, purchaseOrder: data });
  }, []);

    const buttonColor = value => {
      return value === "Approved" ? "primary" : "secondary"
    }
  
  const {data} = props;
  // const {value} = (!!data.documentItemsQuantity || !data.supplierWebsite) && props;
  const {value} = props;

  return (
    <span className="button-color">
      {value && value.sort((a, b) => a.rank - b.rank)
        .map(item => {
          const { action, id } = item;
          return (
            <Button
              style={{ width: 50 }}
              variant="contained"
              onClick={e => handleClick(id, action)}
              className={classnames(["jr-btn", "jr-btn-grid"])}
            >
              {action}{id}
            </Button>
          )
        })}
    </span>
  )
}

