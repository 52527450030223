import {
  FETCH_DATA,
  ADD_DOCUMENT,
  ADD_DOCUMENTS,
  CHANGE_STATE,
  COMPLETE,
  ADD_INVOICE,
  POST_INVOICE,
  DOCUMENT_BOOKMARKS,
  CHANGE_TAB,
  LOADED
} from 'constants/Document';
import ActionRenderer from '../app/routes/Orders/ActionRenderer';
import Enumerable from 'linq';
import isEmpty from 'lodash/isEmpty';
import {
  documentSelector,
  tabDocumentSelector, 
  convertSummaryDocuments
} from 'selectors/Document';

export const initState = {
  data: [],
  data1: [],
  data2: [],
  data3: [],
  data4: [],
  data5: [],
  data6: [],
  loaded1: false,
  loaded2: false,
  loaded3: false,
  loaded4: false,
  loaded5: false,
  loaded6: false,
  currentTabId: 1
}

const duplicate = (state, action, func) => {
  let rr = state;
  Enumerable.range(1, 6).forEach(index => rr = {...rr, [`data${index}`]: func(rr, index, action)});
  return rr;
}

const addDocument = (state, tabId, action) => {
  const dataTab = `data${tabId || ''}`;
  var result = tabDocumentSelector(tabId, state[dataTab], action);    
  return result;
}

const postInvoice = (state, tabId, action) => {
  const dataTab = `data${tabId || ''}`;
  var res1 = state[dataTab].map(document => {
      const {documentKey} = action.data;
      if (document.key === documentKey) {
        return {
          ...document,
          postInvoice: true
        }
      }
      else {
        return document;
      }
  });
  return res1;
}


const addInvoice = (state, tabId, action) => {
  const dataTab = `data${tabId || ''}`;
  return state[dataTab].map(document => {
      const {documentNumber, invoiceNumber} = action.data;
      if (document.documentNumber === documentNumber) {
        return {
          ...document,
          invoiceNumber
        }
      }
      else {
        return document;
      }
  });
}

const changeState = (state, tabId, action) => {
  const dataTab = `data${tabId || ''}`;
  return state[dataTab].map(document => {
      const {documentId, documentAllowedCombinations, state} = action.data;
      if (document.id === documentId) {
        return {
          ...document,
          allowedCombinations: documentAllowedCombinations,
          stateCode: state.code,
          stateName: state.name,
          stateId: state.id
        }
      }
      else {
        return document;
      }
  });
}

const complete = (state, action) => {
  const dataTab = `data${action.tabId || ''}`;
  return state[dataTab].map(document => {
      if (document.id === action.id) {
        return {
          ...document,
          completed: action.value
        }
      }
      return document;
    });
}


const reducer = (state = initState, action) => {
  const dataTab = `data${action.tabId || ''}`;
  const loadedTab = `loaded${action.tabId || ''}`;
  
  switch (action.type) {
    case CHANGE_TAB:
      return {
        ...state,
        currentTabId: action.tabId
      }

    case FETCH_DATA:
      var result = [];
      if (action.changedGlobalFilter) {
        result = {
          ...state,
          data: [],
          data1: [],
          data2: [],
          data3: [],
          data4: [],
          data5: [],
          data6: [],
          loaded1: false,
          loaded2: false,
          loaded3: false,
          loaded4: false,
          loaded5: false,
          loaded6: false,
          [dataTab]: convertSummaryDocuments(action),
          [loadedTab]: true
        }
      } else {
        result = {
          ...state,
          [dataTab]: convertSummaryDocuments(action),
          [loadedTab]: true
        }  
      }
      return result;

    case ADD_INVOICE: 
      return duplicate(state, action, addInvoice);

    case POST_INVOICE:
      return duplicate(state, action, postInvoice);

    case ADD_DOCUMENT:
      const document = convertSummaryDocuments(action);
      return duplicate(state, document[0], addDocument);

    case ADD_DOCUMENTS:
      return {
        ...state,
        [dataTab]: [
          ...state[dataTab],
          ...action.data
        ]
      }

    case CHANGE_STATE:
      return duplicate(state, action, changeState);

    case COMPLETE:
      return duplicate(state, action, complete);

    case LOADED:
      return {
        ...state,
        [loadedTab]: action.data
      }


    default:
      return state;
  }
}

export default reducer;