import Enumerable from 'linq';
import moment from 'moment';
import { DOCUMENT_BOOKMARKS } from 'constants/Document';
import {startsWith} from 'lodash';

const CANCEL_STATE = 'CANCELED';

export const documentSelector = documents => {
  return Enumerable.from(documents)
    .select(s => {
      if (s.stateCode === CANCEL_STATE) {
        if (startsWith(s.reference, "create txt") || startsWith(s.reference, "Upload PDF")) {
          clearReference(s);
        }
        if (s.supplierCode === "?") s.supplierCode = "";
        if (s.clientCode === "TBC") s.clientCode = "";
        if (s.countryCode === "?") s.countryCode = "";
      } 
      return s;
    }).toArray();
}


export const tabDocumentSelector = (tabId, documents, newDocument) => {
  var linq = Enumerable.from(documents);
  
  var data = [];
  if (linq.any(w => w.documentNumber === newDocument.documentNumber)) {
    data = documents.map(doc => {
      if (doc.documentNumber === newDocument.documentNumber)
        return newDocument;
      else 
        return doc;
    });
  }
  else {
    data = [...documents, newDocument];
  }

  switch (tabId) {
    case DOCUMENT_BOOKMARKS.LAST_6_MONTHS:
      return last6MonthsDocumentSelector(data);

    case DOCUMENT_BOOKMARKS.SAGE_REQUIRED:
      return sageRequiredDocumentSelector(data);

    case DOCUMENT_BOOKMARKS.PRINTED:
      return printedDocumentSelector(data);

    case DOCUMENT_BOOKMARKS.AWAITING_CONFIRMATION:
      return awaitingConfirmationDocumentSelector(data);

    default:
      return data;
  }
}

const last6MonthsDocumentSelector = documents => {
  const date = moment().add(-6, 'month').calendar();
  return Enumerable
    .from(documents)
    .where(w => w.createdDate >= date)
    .orderByDescending(o => o.documentNumber)
    .toArray();
}

const sageRequiredDocumentSelector = documents => 
  Enumerable
    .from(documents)
    .where(w => w.sageRequired)
    .orderByDescending(o => o.documentNumber)
    .toArray();

const printedDocumentSelector = documents => 
  Enumerable
    .from(documents)
    .where(w => w.stateCode === 'PRINTED')
    .orderByDescending(o => o.documentNumber)
    .toArray();

const awaitingConfirmationDocumentSelector = documents => 
  Enumerable
    .from(documents)
    .where(w => w.stateCode === 'SENT' || w.stateCode === 'REJECTED')
    .orderByDescending(o => o.documentNumber)
    .toArray();

const clearReference = document => {
  document.reference = '';
}

const clearDocument = document => {
  document.amendment = '';
  document.buyer = '';
  document.clientCode = '';
  document.completed = '';
  document.contractNumber = '';
  document.countryCode = '';
  document.createdPdfDate = '';
  document.createdTxtDate = '';
  document.currencySign = '';
  document.delivered = '';
  document.deliveredQuantity = '';
  document.deliveryAddress = '';
  document.documentItemsQuantity = '';
  document.held = false;
  document.invoiced = '';
  document.invoicedPercent = '';
  document.orderDate = '';
  document.producedQuantity = '';
  document.quantityOs = '';
  document.received = '';
  document.reference = '';
  document.requiredDate = '';
  document.sageStateName = '';
  document.shippedQuantity = '';
  document.stockType = '';
  document.supplierCode = '';
  document.toSite = '';
  document.total = '';
  document.tracedDeliveryAddress = '';
  document.uploadedPdfDate = '';
  document.uploadedTxtDate = '';
  document.warehouse = '';
  document.invoiceNumber = '';
  document.postInvoice = '';
  document.warning = false;

  return document;
}

export const convertSummaryDocuments = action => {
  const {
    document,
    allowedCombinations,
    references,
    documentStates,
    sageStates,
    buyers,
    deliveryAddresses,
    tracedDeliveryAddresses,
  } = action.data;

  return document.map(item => {
    return {
      id: item.id,
      key: item.key,
      warning: item.wg,
      documentNumber: item.n,
      clientCode: item.cc,
      supplierCode: item.s,
      amendment: item.a,
      countryCode: item.coc,
      createdDate: item.cd,
      reference: references[item.r].item2,
      deliveryAddress: deliveryAddresses[item.da].item2,
      tracedDeliveryAddress: tracedDeliveryAddresses[item.tda].item2,
      stateCode: (documentStates.filter(f => f.id === item.ds)[0] || {}).code,
      stateName: (documentStates.filter(f => f.id === item.ds)[0] || {}).name,
      sageStateName: (sageStates.filter(f => f.id === item.ss)[0] || {}).name,
      completed: item.ct,
      held: item.h,
      documentItemsQuantity: item.di,
      orderDate: item.od,
      requiredDate: item.rd,
      delivered: item.dr,
      producedQuantity: item.pq,
      shippedQuantity: item.sq,
      deliveredQuantity: item.dq,
      invoicedPercent: item.ip,
      received: item.re,
      invoiced: item.i,
      quantityOs: item.qs,
      invoicedPercent: item.ip,
      currencySign: item.cs,
      total: item.t,
      stockType: item.st,
      contractNumber: item.cn,
      uploadedPdfDate: item.up,
      createdPdfDate: item.cp,
      uploadedTxtDate: item.ut,
      createdTxtDate: item.ctd,
      warehouse: item.wh,
      toSite: item.ts,
      buyer: (buyers.filter(f => f.id === item.b)[0] || {}).name,
      allowedCombinations: allowedCombinations.filter(f => f.prevId === item.ds),
      sageRequired: item.sr,
      invoiceNumber: item.in,
      postInvoice: item.pi
    };
  })
}