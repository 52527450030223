import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
// import Footer from 'components/Footer';
// import Tour from '../components/Tour/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import ColorOption from 'containers/Customizer/ColorOption';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from '@bit/hlouzek.rhplus.top-nav';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import {ToastContainer} from 'react-toastify';
import '@bit/hlouzek.rhplus.ag-grid-styles/AgGridStyles/ag-theme-radim.scss';

import Clients from './routes/Clients/routes/Root';
import Client from './routes/Clients/routes/Client';


class App extends React.Component {
  render() {
    const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }
    return (
      <div className={`app-container ${drawerStyle}`}>
        {/* <Tour/> */}
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
              <TopNav styleName="app-top-header" />}
            <Header />
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
              <TopNav />}
          </div>
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <ToastContainer draggable />
              <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/orders`} />
                <Route exact path={`${match.url}/orders`} component={asyncComponent(() => import('./routes/Orders'))} />
                <Route path={`${match.url}/orders/chat`} component={asyncComponent(() => import('./routes/Chat/redux'))}/>
                <Route path={`${match.url}/detail/:documentNumber`} component={asyncComponent(() => import('./routes/Detail'))} />
                <Route path={`${match.url}/ordertracker`} component={asyncComponent(() => import('./routes/OrderTracker'))} />
                <Route path={`${match.url}/reports`} component={asyncComponent(() => import('./routes/Reports'))} />
                <Route path={`${match.url}/reportitems/:reportId`} component={asyncComponent(() => import('./routes/ReportItems'))} />
                <Route path={`${match.url}/stockstatusitems/:id`} component={asyncComponent(() => import('./routes/StockStatusItems'))} />
                <Route path={`${match.url}/documentanalysis`} component={asyncComponent(() => import('./routes/DocumentAnalysis'))} />
                <CacheRoute exact path={`${match.url}/clients`} component={Clients} />
                <Route exact path={`${match.url}/sites/:site`} component={asyncComponent(() => import('./routes/Sites'))} />
                <Route exact path={`${match.url}/contracts/:contractId`} component={asyncComponent(() => import('./routes/Sites'))} />
                <CacheRoute path={`${match.url}/clients/:clientCode`} component={Client} />
                <Route exact path={`${match.url}/receive/:key`} component={asyncComponent(() => import('./routes/Receive'))} />
                <Route exact path={`${match.url}/reject/:key`} component={asyncComponent(() => import('./routes/Reject'))} />
              </Switch>
            </div>
          </main>
        </div>
        <ColorOption />
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition }
};
export default withRouter(connect(mapStateToProps)(App)); 