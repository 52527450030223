export const FETCH_DATA = 'FETCH_DATA';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const ADD_DOCUMENTS = 'ADD_DOCUMENTS';
export const CHANGE_STATE = 'CHANGE_STATE';
export const COMPLETE = 'COMPLETE';
export const CHANGE_TAB = 'CHANGE_TAB';
export const LOADED = 'LOADED';
export const ADD_INVOICE = 'ADD_INVOICE';
export const POST_INVOICE = 'POST_INVOICE';

export const DOCUMENT_BOOKMARKS = {
  ALL: 1,
  SAGE_REQUIRED: 1,
  CONNECTED_SUPPLIERS: 2
};

export const PURCHASE_ORDER_EMAIL = 'purchaseorder@railston.com';
export const PURCHASE_ORDER_RECIPIENTS = 'radim.havranek@strandhill.eu;richard.spencer@railston.com';

export const PURCHASE_ORDER_CHANNEL = 'PURCHASE_ORDER_CHANNEL';