import * as React from 'react';

export const filterInit = {
  all: true,
  loading: false,
  onFilterChange: () => {}
};

export const FilterContext = React.createContext(filterInit);
export const useFilterContext = () => React.useContext(FilterContext);

