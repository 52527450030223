export default [
    {
      id: 1,
      name: 'Alex Dolgove',
      thumb: 'https://via.placeholder.com/128x128',
      status: 'away',
      mood: 'English versions from the 1914 translation by H. Rackham',
      lastMessage: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
      unreadMessage: '',
      lastMessageTime: '20 min ago',
      recent: false
    }, {
      id: 2,
      name: 'Domnic Brown',
      thumb: 'https://via.placeholder.com/208x208',
      status: 'online',
      mood: 'English versions from the 1914 translation by H. Rackham',
      lastMessage: 'It is a long established fact',
      unreadMessage: '4',
      lastMessageTime: 'Yesterday',
      recent: true
    }, {
      id: 3,
      name: 'Domnic Harris',
      thumb: 'https://via.placeholder.com/150x150',
      status: 'offline',
      mood: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
      lastMessage: 'There are many variations of passages of ',
      unreadMessage: '',
      lastMessageTime: '20/11/17',
      recent: false
    }, {
      id: 4,
      name: 'Garry Sobars',
      thumb: 'https://via.placeholder.com/150x150',
      status: 'away',
      mood: 'It is a long established fact',
      lastMessage: 'English versions from the 1914 translation by H. Rackham',
      unreadMessage: '3',
      lastMessageTime: 'Yesterday',
      recent: true
    }, {
      id: 5,
      name: 'Jeson Born',
      thumb: 'https://via.placeholder.com/150x150',
      status: 'away',
      mood: 'I must explain to you how all this mistaken idea of denouncing ',
      lastMessage: 'It is a long established fact',
      unreadMessage: '',
      lastMessageTime: 'Monday',
      recent: true
    }, {
      id: 6,
      name: 'Jimmy Jo',
      thumb: 'https://via.placeholder.com/150x150',
      status: 'online',
      mood: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
      lastMessage: 'All the Lorem Ipsum generators on the',
      unreadMessage: '',
      lastMessageTime: 'Friday',
      recent: false
    }, {
      id: 7,
      name: 'John Smith',
      thumb: 'https://via.placeholder.com/150x150',
      status: 'away',
      mood: 'There are many variations of passages of ',
      lastMessage: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
      unreadMessage: '',
      lastMessageTime: 'Tuesday',
      recent: true
    }, {
      id: 8,
      name: 'Kadir M',
      thumb: 'https://via.placeholder.com/150x150',
      status: 'online',
      mood: 'All the Lorem Ipsum generators on the',
      lastMessage: 'I must explain to you how all this mistaken idea of denouncing ',
      unreadMessage: '5',
      lastMessageTime: 'Monday',
      recent: false
    }, {
      id: 9,
      name: 'Jimmy Jon',
      thumb: 'https://via.placeholder.com/260x260',
      status: 'offline',
      mood: 'There are many variations of passages of ',
      lastMessage: 'There are many variations of passages of ',
      unreadMessage: '',
      lastMessageTime: '30 Min ago',
      recent: false
    }, {
      id: 10,
      name: 'Stella Johnson',
      thumb: 'https://via.placeholder.com/120x120',
      status: 'offline',
      mood: 'It is a long established fact',
      lastMessage: 'English versions from the 1914 translation by H. Rackham',
      unreadMessage: '',
      lastMessageTime: 'Yesterday',
      recent: false
    }, {
      id: 11,
      name: 'Steve Smith',
      thumb: 'https://via.placeholder.com/150x150',
      status: 'online',
      mood: 'The standard chunk of Lorem Ipsum used since the 1500s',
      lastMessage: 'The standard chunk of Lorem Ipsum used since the 1500s',
      unreadMessage: '2',
      lastMessageTime: 'Monday',
      recent: false
    }
  ]