import React, { useContext, useEffect, useReducer } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import {
  PageContext,
  usePageContext,
  PageReducer,
} from "@bit/hlouzek.rhplus.page/page";
import configureStore, { history } from "./store";
import App from "./containers/App";
import LocalIdb from "./localIdb";
import { DocumentContext, useDocumentReducer } from "contexts/Document";
import CacheBuster from "@bit/hlouzek.rhplus.cache-buster";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import { LoaderContainer } from "@bit/hlouzek.rhplus.loader";
import { RHPLUS_PURCHASEORDER_LOADING_CHANNEL } from "constants/PubNubTypes";
import "@bit/hlouzek.rhplus.ant-tabs/ant-tabs.scss";
import DocumentContainer from "contexts/DocumentContainer";

// configuraton AgGrid Enterprise
LicenseManager.setLicenseKey(
  "MTU1MzczMTIwMDAwMA==555b6946f1f4ee082a509e13fbc32c10"
);

// configuration Redux store
export const store = configureStore();

// configuration PubNub
const pubnubConfig = require(`${process.env.REACT_APP_PUBNUB}`);

const Apps = (props) => {
  const initialPageContext = usePageContext();
  const [pageState, pageDispatch] = useReducer(PageReducer, initialPageContext);

  const { open } = LocalIdb();

  useEffect(() => {
    var store = open();
  }, []);

  return (
    <CacheBuster packageJson={import("../package.json")}>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <LoaderContainer
            pubnubConfig={pubnubConfig}
            channel={RHPLUS_PURCHASEORDER_LOADING_CHANNEL}
          >
            <PageContext.Provider value={{ pageState, pageDispatch }}>
              <DocumentContainer pubnubConfig={pubnubConfig}>
                <App {...props} />
              </DocumentContainer>
            </PageContext.Provider>
          </LoaderContainer>
        );
      }}
    </CacheBuster>
  );
};

export default function MainApp() {
  return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={Apps} />
          </Switch>
        </ConnectedRouter>
      </Provider>
  );
}
