import {
  FETCH_DATA,
  ADD_DOCUMENT,
  ADD_DOCUMENTS,
  CHANGE_STATE,
  ADD_INVOICE,
  POST_INVOICE,
  COMPLETE,
  CHANGE_TAB,
  LOADED
} from 'constants/Document';

export const fetchData = (data, tabId, changedGlobalFilter) => {
  return {
    type: FETCH_DATA,
    data,
    tabId,
    changedGlobalFilter
  }
}

export const changeTab = tabId => {
  return {
    type: CHANGE_TAB,
    tabId
  }
}

export const addDocument = (data, tabId) => {
  return {
    type: ADD_DOCUMENT,
    data,
    tabId
  }
}

export const addDocuments = (data, tabId) => {
  return {
    type: ADD_DOCUMENTS,
    data,
    tabId
  }
}

export const changeState = (data, tabId) => {
  return {
    type: CHANGE_STATE,
    data,
    tabId
  }
}

export const addInvoice = (data, tabId) => {
  return {
    type: ADD_INVOICE,
    data,
    tabId
  }
}

export const postInvoice = (data, tabId) => {
  return {
    type: POST_INVOICE,
    data,
    tabId
  }
}

export const complete = (id, value, tabId) => {
  return {
    type: COMPLETE,
    id,
    value,
    tabId
  }
}

export const loaded = (data, tabId) => {
  return {
    type: LOADED,
    data, 
    tabId
  }
}