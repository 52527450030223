import * as React from 'react';
import reducer, {initState} from 'reducers/Document';
import {
  fetchData,
  addDocument,
  addDocuments,
  changeState,
  complete,
  changeTab,
  loaded,
  addInvoice,
  postInvoice
} from 'actions/Document';

export const DocumentContext = React.createContext(initState);
export const useDocumentReducer = () => React.useReducer(reducer, initState);
export const useDocumentContext = () => React.useContext(DocumentContext);

export const useSortableDocumentState = () => {
  const Enumerable = require('linq');
  const {documentState: {data}} = useDocumentContext();
  
  return Enumerable.from(data)
    .toArray();
}

export default function useDocument(dispatch) {
  const changeTabReducer = tabId => dispatch(changeTab(tabId));
  const fetchDataReducer = (data, tabId, changedGlobalFilter) => dispatch(fetchData(data, tabId, changedGlobalFilter));
  const addDocumentReducer = (data, tabId) => dispatch(addDocument(data, tabId));
  const addInvoiceReducer = (data, tabId) => dispatch(addInvoice(data, tabId));
  const postInvoiceReducer = (data, tabId) => dispatch(postInvoice(data, tabId));
  const changeStateReducer = (data, tabId) => dispatch(changeState(data, tabId));
  const addDocumentsReducer = (data, tabId) => dispatch(addDocuments(data, tabId));
  const completeReducer = (id, value, tabId) => dispatch(complete(id, value, tabId));
  const loadedReducer = (data, tabId) => dispatch(loaded(data, tabId));

  return {
    fetchData: fetchDataReducer,
    addDocument: addDocumentReducer,
    addDocuments: addDocumentsReducer,
    changeState: changeStateReducer,
    complete: completeReducer,
    changeTab: changeTabReducer,
    loaded: loadedReducer,
    addInvoice: addInvoiceReducer,
    postInvoice: postInvoiceReducer 
  }
}