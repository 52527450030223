import React, {useContext} from 'react';

export const initialPageContext = {
  title: '',
  subtitle: '',
  headerContent: '',
  buttons: [],
  activeButton: null,
  iconButtons: [],
  icons: [],
  handlers: [],
  NODE_ENV: 'production',
  logoVisible: true,
  mapSearchBox: false
}

const PageContext = React.createContext(initialPageContext);
export default PageContext;

export const usePageContext = () => {
  return useContext(PageContext);
}
