import * as React from 'react';
import useDocument, { DocumentContext, useDocumentReducer } from 'contexts/Document';
import PubSub from '@bit/hlouzek.rhplus.pub-sub';
import uuidv4 from 'uuid/v4';
import {PURCHASE_ORDER_CHANNEL} from 'constants/Document';

// var pubsub = new PubSub({}, []);
var pubsub = {};
const _key = uuidv4();


const uncapitalizeKeys = (obj) => {
  const isObject = o => Object.prototype.toString.apply(o) === '[object Object]'
  const isArray = o => Object.prototype.toString.apply(o) === '[object Array]'
  
  let transformedObj = isArray(obj) ? [] : {}
  
  for (let key in obj) {
    // replace the following with any transform function
    const transformedKey = key.replace(/^\w/, (c, _) => c.toLowerCase())

    if (isObject(obj[key]) || isArray(obj[key])) {
      transformedObj[transformedKey] = uncapitalizeKeys(obj[key])
    } else {
      transformedObj[transformedKey] = obj[key]
    }
  }
  return transformedObj
}

const DocumentContainer = (props) => {
  const { children, pubnubConfig } = props;

  pubsub = new PubSub(pubnubConfig, [PURCHASE_ORDER_CHANNEL]);
  const [documentState, documentDispatch] = useDocumentReducer();  
  const {addDocument} = useDocument(documentDispatch);
  
  React.useEffect(() => {
    pubsub.addListener({
      message: messageObject => {
        const {channel, message} = messageObject;
        const uncapitalizedMessage = uncapitalizeKeys(message);
        
        switch (channel) {
          case PURCHASE_ORDER_CHANNEL:
            addDocument(uncapitalizedMessage);
            break;
        }
      }
    })
  }, [])
  
  return (
    <React.Fragment>
      <DocumentContext.Provider value={{documentState, documentDispatch}}>
          {children}
      </DocumentContext.Provider>
    </React.Fragment>
  )
}

export default DocumentContainer;