import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
  tenant: '58ec973f-9c94-4e25-8019-d22e334fc973',
  clientId: '3d83c3c7-24e7-4cc0-bf88-716c5d0d9efd',
  endpoints: {
    api: '3d83c3c7-24e7-4cc0-bf88-716c5d0d9efd',
  },
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

