
const formDataHeaders = {
  'Content-Type': 'multipart/form-data',
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT"
}

const cors = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT"
}
const documentSummary = {
  url: `/api/subscriber/document/documentsummary`,
  methodType: "POST",
  version: "1.0",
  compression: true
}

const documentStates = {
  url: `/api/gateway/document/documentStates`,
  methodType: "GET",
  version: "1.0"
}

const documentAllowedCombinations = {
  url: `/api/gateway/document/documentAllowedCombinations`,
  methodType: "GET",
  version: "1.0"
}

const uploadDocuments = {
  url: `/api/gateway/document/uploaddocuments`,
  methodType: "PUT",
  headers: formDataHeaders,
  version: "1.0"
}

const uploadReport = {
  url: `/api/gateway/document/uploadreport`,
  methodType: "PUT",
  // compression: true,
  headers: formDataHeaders,
  version: "1.0"
}

const documentDetail = (documentKey) => {
  return {
    url: `/api/gateway/document/documentdetail/${documentKey}`,
    methodType: "GET",  
    version: "1.0"
  }
}

const documentRevisionsDetail = (documentNumber) => {
  return {
    url: `/api/gateway/document/documentrevisionsdetail/${documentNumber}`,
    methodType: "GET",  
    version: "1.0",
    headers: cors
  }
}

const stockTypes = () => {
  return {
    url: `/api/gateway/document/stocktypes`,
    methodType: "GET",
    version: "1.0"
  }
}

const orderTracker = {
  url: `/api/subscriber/document/ordertracker`,
  methodType: "POST",
  compression: true,
  version: "1.0"
}

const orderTrackerClients = {
  url: `/api/gateway/document/ordertrackerclients`,
  methodType: "POST",
  compression: true,
  version: "1.0"
}

const documentChangeState = {
  url: `/api/gateway/document/documentchangestate`,
  methodType: "POST",
  version: "1.0"
}

const documentChange = {
  url: `/api/gateway/document/documentchange`,
  methodType: "POST",
  version: "1.0",
  showSuccessNotification: true,
  successNotificationMessage: 'Document has been changed'
}

const createOutInvoice = {
  url: `/api/gateway/accountancy/createOutInvoice`,
  methodType: "POST",
  version: "1.0"
}

const documentItemTooltipInfo = {
  url: `/api/gateway/document/documentitemtooltipinfo`,
  methodType: "POST",
  version: "1.0"
}

const getDocumentFile = (id) => {
  return {
      url: `/api/gateway/file/getdocumentfile/${id}`,
      methodType: "GET",
      responseType: "arraybuffer",
      headers: {
        'Content-Type': 'application/json',
        //'Accept': 'application/pdf'
      },
      version: "1.0"
  }
}

const getDocumentFiles = (documentId, typeId) => {
  return {
      url: `/api/gateway/file/getdocumentfiles/${documentId}/${typeId}`,
      methodType: "GET",
      version: "1.0"
  }
}

const checkFilesUpdate = {
  url: `/api/gateway/document/checkfilesupdate`,
  methodType: "PUT",
  version: "1.0",
  headers: formDataHeaders
}

const sendPurchaseOrderEmail = {
  url: `/api/gateway/document/sendpurchaseorderemail`,
  methodType: "PUT",
  version: "1.0",
  headers: formDataHeaders,
}

const sendInvoiceEmail = {
  url: `/api/gateway/document/sendinvoiceemail`,
  methodType: "PUT",
  version: "1.0",
  headers: formDataHeaders,
}

const postInvoice = {
  url: `/api/gateway/accountancy/postinvoice`,
  methodType: "PUT",
  version: "1.0",
  headers: formDataHeaders,
}

const reportItemsView = {
  url: `/api/gateway/document/reportitemsview`,
  methodType: "POST",
  version: "1.0"
}

const clientSummary = {
  url: `/api/gateway/client/clientsummary`,
  methodType: "POST",
  version: "1.0"
}

const clientContracts = {
  url: `/api/gateway/client/clientcontracts`,
  methodType: "POST",
  version: "1.0"
}

const siteDocumentItems = {
  url: `/api/gateway/client/siteDocumentItems`,
  methodType: "POST",
  version: "1.0"
}

const recipients = {
  url: `/api/gateway/document/recipients`,
  methodType: "POST",
  version: "1.0"
}

const reportSummary = {
  url: `/api/gateway/report/reportsummary`,
  methodType: "POST",
  version: "1.0"
}

const reportItemSummary = {
  url: `/api/subscriber/report/reportitemsummary`,
  methodType: "POST",
  version: "1.0",
  compression: true
}

const reportItemSummaryView = {
  url: `/api/subscriber/report/reportitemsummaryview`,
  methodType: "POST",
  version: "1.0",
  compression: true
}


const reportItemsByProduct = {
  url: `/api/subscriber/report/reportitemsbyproduct`,
  methodType: "POST",
  version: "1.0",
  compression: true
}

const stockStatusItems = {
  url: `/api/sage/file/stockstatusitems`,
  methodType: "POST",
  version: "1.0",
  compression: true
}

const documentComplete = {
  url: `/api/gateway/document/complete`,
  methodType: "POST",
  version: "1.0",
}

const documentHold = {
  url: `/api/gateway/document/hold`,
  methodType: "POST",
  version: "1.0",
}

const documentAnalysis = {
  url: `/api/subscriber/analysis/documentanalysis`,
  methodType: "POST",
  version: "1.0",
  compression: true
}


export const apis = {
  documentSummary,
  documentStates,
  documentAllowedCombinations,
  uploadDocuments,
  documentDetail,
  documentRevisionsDetail,
  stockTypes,
  orderTracker,
  orderTrackerClients,
  documentChange,
  documentChangeState,
  documentItemTooltipInfo,
  getDocumentFile,
  checkFilesUpdate,
  uploadReport,
  sendPurchaseOrderEmail,
  reportItemsView,
  clientSummary,
  clientContracts,
  siteDocumentItems,
  recipients,
  reportSummary,
  reportItemSummary,
  documentComplete,
  documentHold,
  getDocumentFiles,
  documentAnalysis,
  stockStatusItems,
  reportItemsByProduct,
  createOutInvoice,
  sendInvoiceEmail,
  postInvoice
}