import * as React from 'react';
import DockLayout from 'rc-dock';
import { FilterContext, filterInit } from './reducer/FilterContext';
import { LayoutContext, LayoutInit } from './reducer/LayoutContext';
import useDocument, { useDocumentContext, useSortableDocumentState } from 'contexts/Document';
import Clients from './Clients';
import { apis } from 'api/apiService';
import { useApi } from '@bit/hlouzek.rhplus.api';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader';

const DockLayoutDefault = (props) => {
  return {
    dockbox: {
      mode: 'horizontal',
      children: [
        {
          tabs: [{ id: 't1', title: "Clients", content: <Clients {...props} /> }],
        },
      ]
    },
    floatbox: {
      mode: 'float',
      children: [
        {
          tabs: [],
          x: window.innerWidth - 350, y: 50, w: 300, h: 100
        }
      ]
    }
  }
};

export default function Index(props) {
  const [filters, setFilters] = React.useState(filterInit);
  const { loading, setLoading, setMessage, key } = useLoaderContext();


  const onFilterChange = e => {
    setFilters(e);
  }

  const onLoadingChange = e => setLoading(e);

  return (
    <FilterContext.Provider value={{ filters, onFilterChange }}>
      <LayoutContext.Provider value={{ loading, onLoadingChange }}>
        <DockLayout
          {...props}
          defaultLayout={DockLayoutDefault(props)}
          style={{ position: 'absolute', left: 0, top: 60, right: 0, bottom: 0 }}
        />
      </LayoutContext.Provider>
    </FilterContext.Provider>
  )
}