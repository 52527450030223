import usePage from './Page';
import PageContext from './redux/PageContext';
import PageReducer from './redux/PageReducer';
import HeaderTitle from './HeaderTitle';
import HeaderSubtitle from './HeaderSubtitle';
import HeaderContent from './HeaderContent';
import IconButtons from './IconButtons';
import ButtonGroups from './ButtonGroups';
import Icons from './Icons';

export default usePage;
export {
  PageContext, 
  PageReducer,
  HeaderTitle,
  HeaderSubtitle,
  HeaderContent,
  IconButtons,
  Icons,
  ButtonGroups,
}
export {usePageContext} from './redux/PageContext';

